import { Fragment, useLayoutEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../auth/auth-provider'
import Header from '../header/header'

export function Layout() {
  const { checkSession } = useAuth()
  const [isFetching, setIsFetching] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useLayoutEffect(() => {
    setIsFetching(true)
    async function check() {
      await checkSession()
      setIsFetching(false)
      navigate(location, { replace: true })
    }

    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Header />

      <div id="wrapper">
        {isFetching ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h3>Loading...</h3>
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </Fragment>
  )
}
