import Auth from '@aws-amplify/auth'
import React from 'react'

// Add envs configuration
type EnvType = "dev" | "staging"


/*
{
  aws_cognito_region: string (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: string (required) - Amazon Cognito User Pool ID is required now that we have multiple environments.
  aws_user_pools_web_client_id: string (required) - Amazon Cognito App Client ID is required now that we have multiple environments (App client secret needs to be disabled)
}
*/

const configurations = {
  dev: {
    aws_cognito_region: 'us-east-2', 
    aws_user_pools_id: process.env.REACT_APP_DEV_USER_POOL_ID, 
    aws_user_pools_web_client_id: process.env.REACT_APP_DEV_USER_POOL_CLIENT_ID, 
  },
  staging: {
    aws_cognito_region: 'us-east-2', 
    aws_user_pools_id: process.env.REACT_APP_STAGING_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_STAGING_USER_POOL_CLIENT_ID, 
  }
};

interface AuthContextType {
  user: any
  token: string
  checkSession: () => Promise<void>
  signin: (username: string, password: string) => Promise<void>
  signout: () => Promise<void>
  setEnv: (env: EnvType) => void
  env: EnvType
}


let AuthContext = React.createContext<AuthContextType>(null!)

async function getToken(): Promise<string> {
  const session = await Auth.currentSession()
  const token = session.getIdToken().getJwtToken()
  return token
}

//Force initialization to avoid Amplify/Auth error
Auth.configure(configurations["dev"])

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<any>(null)
  const [token, setToken] = React.useState<string>('')
  const [env, setEnv] = React.useState<EnvType>("dev")

  React.useEffect(() => {
    Auth.configure(configurations[env]);
  }, [ env ])

  async function checkSession() {
    console.log('Checking session.')
    try {
      const user = await Auth.currentUserInfo()
      setUser(user)
      if (user === null) return
      console.log('user found! ', user)

      const token = await getToken()
      setToken(token)
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  let signin = async (username: string, password: string) => {
    const user = await Auth.signIn({
      username,
      password,
    })
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()
    setToken(token)
    setUser(user)
    console.log('user: ', user)
  }

  let signout = async () => {
    await Auth.signOut()
    setUser(null)
    setToken('')
  }

  let value = { 
    user, signin, signout, 
    token, checkSession, 
    setEnv, env
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return React.useContext(AuthContext)
}
