import React from 'react'
import './graph-card.css'

export const GraphCard: React.FunctionComponent<{
  name: string
  url: string
  onClick: any
}> = ({ name, url, onClick }) => {
  return (
    <div onClick={() => onClick(url)} className="graph-card">
      <div className="graph-card__left">
        <h4 className="graph-card__left-title">{name}</h4>
        <span>{url}</span>
      </div>
    </div>
  )
}
