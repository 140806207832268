import { Fragment, useState } from 'react'
import { useAuth } from '../auth/auth-provider'

export const ApiEndpointInput: React.FunctionComponent<{
  onSubmit: any
  isLoading: boolean
}> = ({ onSubmit, isLoading }) => {
  const [url, setUrl] = useState<string>('')

  const handleChange = (e: any) => {
    return setUrl(e.target.value)
  }

  const handleSubmit: React.FormEventHandler = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(url)
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <input type="text" onChange={handleChange} value={url} />
        <button disabled={isLoading} type="submit">
          {isLoading ? 'Connecting...' : 'Connect'}
        </button>
      </form>
    </Fragment>
  )
}

const testQuery = async (url: string, token: string) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    credentials: 'omit',
    body: JSON.stringify({
      query: `
        query {
          __schema {
            __typename
          }
        }
      `,
    }),
  })
}

const isValidFormat = (url: string) => {
  const exp =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  const reg = new RegExp(exp)
  return reg.test(url)
}

export function ConnectToGraphForm({
  onSuccess,
}: {
  onSuccess: (url: string) => void
}) {
  const { token } = useAuth()
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const connectToApi = async (value: string) => {
    if (!isValidFormat(value)) return setError('Invalid url')
    setIsLoading(true)
    setError('')
    try {
      const res = await testQuery(value, token)
      if (res?.status === 200) {
        onSuccess(value)
      } else if (res.status === 404) {
        setError('Api not found. Check the url.')
      } else {
        setError('Unexpected error. Check console')
      }
    } catch (error) {
      setError('Unexpected error. Check console')
      console.log('error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="graph-list-add-new">
      <span>
        Enter your local api endpoint below. Not sure what this is? Check your
        terminal after running `npm start`
      </span>
      {error && <p>{error}</p>}
      <ApiEndpointInput isLoading={isLoading} onSubmit={connectToApi} />
    </div>
  )
}
