import GraphiQL from 'graphiql'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from './auth/auth-provider'

const getUrlFromLocalStorage = (): string | null => {
  const str = localStorage.getItem('bf-api-history')
  return str
}

const GraphiQLWrapper = () => {
  const { graphId } = useParams()
  const { token } = useAuth()
  const [error, setError] = useState<string>('')

  const apiUrl = useMemo(() => {
    if (graphId === 'dev') {
      return 'https://graph.dev.buildforce-engineering.com'
    } else if (graphId === 'staging') {
      return 'https://graph.staging.buildforce-engineering.com'
    } else if (graphId === 'local') {
      return getUrlFromLocalStorage()
    } else {
      setError('Graph not found')
    }
  }, [graphId])

  const graphQLFetcher = async (graphQLParams: any, opts: any) => {
    if (!apiUrl) throw new Error('No API url found.')

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
      ...opts.headers,
    }

    const response = await fetch(apiUrl, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(graphQLParams),
      credentials: 'omit',
    })

    // console.log(response)
    return await response.json()
  }

  return (
    <React.Fragment>
      {error ? (
        <span>{error}</span>
      ) : (
        <GraphiQL fetcher={graphQLFetcher} defaultQuery={''} />
      )}
    </React.Fragment>
  )
}

export default GraphiQLWrapper
