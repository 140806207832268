import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConnectToGraphForm } from '../add-graph-form/add-graph-form'
import { useAuth } from '../auth/auth-provider'
import { GraphCard } from './graph-card/graph-card'

const getHistoryFromLocalStorage = (): string | null => {
  const str = localStorage.getItem('bf-api-history')
  return str
}

const saveUrlToLocalStorage = (url: string) => {
  localStorage.setItem('bf-api-history', url)
}

const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const GraphIndex: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [savedUrl, setSavedUrl] = useState(getHistoryFromLocalStorage)
  const {env} = useAuth()

  const clearUrl = () => {
    setSavedUrl('')
    localStorage.removeItem('bf-api-history')
  }

  const saveUrl = (url: string) => {
    saveUrlToLocalStorage(url)
    setSavedUrl(url)
  }

  const render = () => {
    return (
      <div className="graph-index-wrapper">
        {
          env !== "dev" ? null : (

            <div className="graph-list">
              <div className="graph-section">
            
                <div className="graph-section__title">
                  <h3>Development graphs</h3>
                  <span>Added by you.</span>
                  {savedUrl && <button onClick={clearUrl}>Clear url</button>}
                </div>

                {
                  !savedUrl ? null : (
                    <GraphCard
                      onClick={() => navigate('/graphs/local')}
                      name="Local"
                      url={savedUrl}
                    />
                  )
                }

                {
                  savedUrl ? null : (
                    <ConnectToGraphForm onSuccess={saveUrl} />
                  )
                }
                
              </div>
            </div>

          )
        }
        <div className="graph-section">
          <div className="graph-section__title">
            <h3>Deployed graphs</h3>
          </div>
          <div className="graph-list">
            <GraphCard
              onClick={() => navigate(`/graphs/${env}`)}
              name={capitalizeFirst(env)}
              url={`https://graph.${ env }.buildforce-engineering.com`}
            />
          </div>
        </div>
      </div>
    )
  }

  return render()
}
