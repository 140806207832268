import { FormEvent, FunctionComponent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from './auth/auth-provider'

const LoginForm: FunctionComponent<{}> = () => {
  const { signin, setEnv, env } = useAuth()
  const navigate = useNavigate()
  let location = useLocation()

  //@ts-ignore
  let from = location.state?.from?.pathname || '/'

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const login = async (e: FormEvent) => {
    e.preventDefault()

    setIsFetching(true)
    try {
      await signin(username, password)
      navigate(from, { replace: true })
    } catch (error) {
      console.error(error)
      setError('Something went wrong')
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <div className="login-layout">
      <div className="login-form__wrapper">
        {error}
        <form className="login-form" onSubmit={login}>
          <div>
            <label htmlFor="username">Username</label>
            <br />
            <input
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              type="text"
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <br />
            <input
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </div>
          <div className='login-form__env-buttons'>
            <button 
              className="login-form__env-button"
              onClick={ () => setEnv("dev") }
              disabled={ env === "dev" }
              type="button">
              Dev/Local
            </button>
            &nbsp;
            <button
              className="login-form__env-button"
              onClick={ () => setEnv("staging") }
              disabled={ env === "staging" }
              type="button">
              Staging
            </button>
          </div>
          <button disabled={isFetching ? true : false} type="submit">
            {isFetching ? 'Connecting...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  )
}

export default LoginForm
