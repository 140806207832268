import './App.css'
import { Auth } from '@aws-amplify/auth'
import 'graphiql/graphiql.min.css'
import LoginForm from './login-form'
import { GraphIndex } from './graph-index/graph-index'
import { AuthProvider } from './auth/auth-provider'
import { Route, Routes } from 'react-router-dom'
import { RequireAuth } from './auth/require-auth'
import { Layout } from './layout/layout'
import GraphiQLWrapper from './graphiql'

Auth.configure({
  aws_cognito_region: 'us-east-2', // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID, // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
})

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <RequireAuth>
                <GraphIndex />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<LoginForm />} />
          <Route
            path="graphs/:graphId"
            element={
              <RequireAuth>
                <GraphiQLWrapper />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  )
}

export default App
