import { useNavigate } from 'react-router-dom'
import { useAuth } from '../auth/auth-provider'
import './header.css'

const Header: React.FunctionComponent = () => {
  const { user, signout } = useAuth()
  const navigate = useNavigate()

  return (
    <div className="header">
      <div onClick={() => navigate('/')} className="header__left">
        <h4 className="header__left-title">Buildforce Graph Explorer</h4>
      </div>
      {user ? (
        <div className="header__content">
          <div className="header__content-item">
            <p>
              {user.attributes['given_name']} {user.attributes['family_name']} (
              {user.attributes['custom:account_type']})
            </p>
            <span style={{ fontSize: '0.8rem' }}>{user.username}</span>
          </div>

          <div className="header__content-item">
            <button className="button" onClick={signout}>
              Sign out
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Header
